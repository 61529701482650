import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthLayoutService } from 'src/app/layouts/auth-layout/auth-layout.service';
import Swal from 'sweetalert2';
import { CommonResponse } from 'src/app/models/common-response';
import { LoaderService } from 'src/app/dialogs/loader/loader.service';

declare interface RouteInfo {
    path: string;
    title: string;
    icon: string;
    class: string;
}
/** Administration */
export const ROUTES: RouteInfo[] = [
  { path: '/dashboard', title: 'Dashboard', icon: 'fa-tv text-primary', class: '' },
  { path: '/terminal',  title: 'Terminals', icon: 'fa-mobile-alt text-primary', class: ''},
  { path: '/a-link',    title: 'A-LINKs',   icon: 'fa-link text-primary', class: ''}
];

export const ADMIN_SERVICES: RouteInfo[] = [
  { path: '/service',  title: 'Fees & Commissions',  icon: 'ni-settings text-primary', class: ''},
  { path: '/account-service-balance',  title: 'Service Topup',  icon: 'ni-money-coins text-primary', class: ''},
];

export const ADMIN_PARTNERS: RouteInfo[] = [
  { path: '/partner-accounts',    title: 'Accounts',    icon: 'ni-single-02 text-primary', class: ''},
  { path: '/partner-commissions', title: 'Commissions', icon: 'ni-chart-bar-32 text-primary', class: ''},
  { path: '/partner-balance',     title: 'Topup',       icon: 'ni-money-coins text-primary', class: ''},
  { path: '/partner-sms',         title: 'SMS',         icon: 'ni-send text-primary', class: ''}
];

export const COMMISSION_ROUTES: RouteInfo[] = [
  { path: '/admin-comm-transactions', title: 'Transactions',  icon: 'ni-briefcase-24 text-primary', class: ''},
  { path: '/admin-comm-report',       title: 'Summary',       icon: 'ni-briefcase-24 text-primary', class: ''}
];

export const RERPORTING_ROUTES: RouteInfo[] = [
  { path: '/analytics',  title: 'Transactions',  icon: 'ni-briefcase-24 text-primary', class: ''},
];

export const LOGS_ROUTES: RouteInfo[] = [
  /*{ path: '/icons', title: 'Corporate-Logs',  icon: 'ni-briefcase-24 text-blue', class: '' },
  { path: '/icons', title: 'Terminal-Logs',  icon: 'ni-mobile-button text-blue', class: '' }*/
];

export const SETTINGS_ROUTES: RouteInfo[] = [
  { path: '/admin-supervisor', title: 'Supervisors',  icon: 'ni-single-02 text-blue', class: '' },
  /*{ path: '/icons', title: 'SMS',  icon: 'ni-send text-blue', class: '' },
  { path: '/icons', title: 'A-LINK',  icon: 'ni-planet text-blue', class: '' }*/
];

/** PARTNER */
export const CORP_ACCOUNTING: RouteInfo[] = [
  { path: '/agent-accounting',  title: 'Agent Accounting',  icon: 'ni-money-coins text-blue', class: '' },
  { path: '/collection-report', title: 'Collection Report', icon: 'ni-money-coins text-blue', class: '' }
];

export const CORP_AGENTS: RouteInfo[] = [
  { path: '/corporate-agent',      title: 'Agents',       icon: 'fa-user text-primary',       class: ''},
  { path: '/corporate-manager',    title: 'Managers',      icon: 'fa-user text-primary',      class: ''},
  //{ path: '/corporate-supervisor', title: 'Supervisors',  icon: 'fa-user-tie text-primary',   class: ''}

  /*
  { path: '/corporate-manager',    title: 'Managers',    icon: 'fa-user-shield text-primary',    class: ''},
  { path: '/corporate-financial',  title: 'Financials',  icon: 'fa-user-tag text-primary',    class: ''},
  { path: '/agent-topup-balance', title: 'Topup Balance', icon: 'ni-money-coins text-primary',  class: ''}
  */
];

export const CORPORATE_ROUTES: RouteInfo[] = [
  { path: '/dashboard',           title: 'Dashboard', icon: 'fa-tv text-primary', class: '' },
  { path: '/corporate-agency',    title: 'Agency',    icon: 'fa-store-alt text-primary', class: ''},
  { path: '/corporate-terminal',  title: 'Terminal',  icon: 'fa-mobile-alt text text-primary', class: ''}
  /*{ path: '/icons', title: 'Icons',  icon: 'ni-planet text-blue', class: '' },
  { path: '/maps', title: 'Maps',  icon: 'ni-pin-3 text-orange', class: '' },
  { path: '/user-profile', title: 'User profile',  icon: 'ni-single-02 text-yellow', class: '' },
  { path: '/tables', title: 'Tables',  icon: 'ni-bullet-list-67 text-red', class: '' },
  { path: '/login', title: 'Login',  icon: 'ni-key-25 text-info', class: '' },
  { path: '/register', title: 'Register',  icon: 'ni-circle-08 text-pink', class: '' }*/
];

export const EASYPAY_COMMISSION_ROUTES: RouteInfo[] = [
  { path: '/corp-comm',        title: 'By Service', icon: 'ni-briefcase-24 text-primary', class: ''},
  { path: '/corp-comm-report', title: 'Summary',    icon: 'ni-briefcase-24 text-primary', class: ''},
  // { path: '/agent-comm-report',       title: 'Summary',       icon: 'ni-briefcase-24 text-primary', class: ''}
];

export const CORP_COMMISSION_ROUTES: RouteInfo[] = [
  { path: '/agent-comm-template',     title: 'Services',     icon: 'fa-cogs text-primary',  class: ''},
  { path: '/agent-comm',              title: 'Agents',       icon: 'fa-user text-primary', class: ''},
  { path: '/agent-comm-transactions', title: 'Transactions', icon: 'fa-chart-line text-primary', class: ''},
  { path: '/agent-comm-report',       title: 'Summary',      icon: 'fa-chart-bar text-primary', class: ''}
];

export const CORP_RERPORTING_ROUTES: RouteInfo[] = [
  // { path: '/reporting-sms',      title: 'SMS',          icon: 'ni-send text-primary',  class: ''},
  { path: '/reporting-agent',    title: 'Transactions',        icon: 'ni-single-02 text-primary', class: ''},
  { path: '/reporting-customer', title: 'New Customer', icon: 'ni-single-02 text-primary',  class: ''},
  { path: '/reporting-agency',   title: 'Agency',       icon: 'ni-building text-primary',  class: ''}
];

export const CORP_LOGS_ROUTES: RouteInfo[] = [
  { path: '/agent-logs', title: 'Agent-Logs',  icon: 'ni-single-02 text-blue', class: '' },
  { path: '/terminal-logs', title: 'Terminal-Logs',  icon: 'ni-mobile-button text-blue', class: '' },
  { path: '/agency-logs', title: 'Agency-Logs',  icon: 'ni-building text-blue', class: '' }
];

export const CORP_SETTINGS_ROUTES: RouteInfo[] = [
  //{ path: '/bills', title: 'Bills',  icon: 'ni-single-02 text-blue', class: '' },
  { path: '/settings-admin', title: 'Admin Account',  icon: 'ni-single-02 text-blue', class: '' },
  { path: '/user-profile', title: 'Profile',  icon: 'ni-briefcase-24 text-blue', class: '' }
];

export const CORP_SMS_ROUTES: RouteInfo[] = [
  { path: '/sms-balance', title: 'Balance',  icon: 'ni-money-coins text-blue', class: '' },
  { path: '/sms-report',  title: 'Reporting',  icon: 'ni-send text-blue', class: '' }
];

/**
 * MANAGER
 */
export const MANAGER_GENERAL_ROUTES: RouteInfo[] = [
  { path: '/dashboard',           title: 'Dashboard', icon: 'fa-tv text-primary', class: '' },
  { path: '/corporate-terminal',  title: 'Terminal',  icon: 'fa-mobile-alt text-primary', class: ''}
];

export const CORP_MAN_AGENTS: RouteInfo[] = [
  { path: '/corporate-agent',  title: 'Agents',     icon: 'fa-user text-primary',    class: ''},
];

export const MAN_SMS_ROUTES: RouteInfo[] = [
  // { path: '/sms-balance', title: 'Balance',  icon: 'ni-money-coins text-blue', class: '' },
  { path: '/sms-report',  title: 'Reporting',  icon: 'ni-send text-blue', class: '' }
];

/** 07-10-2021 -> GoPAY Monitoring */
export const GOPAY_MONITORING: RouteInfo[] = [
  { path: '/gopay-account',  title: 'GoPAY Account',  icon: 'ni-money-coins text-primary', class: ''},
  { path: '/gopay-service',  title: 'GoPAY Services',  icon: 'ni-cart text-primary', class: ''},
];

/** 28-09-2022 -> Customer side */
export const CUSTOMERS: RouteInfo[] = [
  { path: '/customer-list',  title: 'Customers',  icon: 'fa-user text-primary', class: ''},
];

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {
  isAdmin = false;
  isPartnerAdmin = false;
  isPartnerManager = false;

  // Admin
  public menuServiceItems: any[];
  public menuPartner: any[];

  // Partner
  public menuPartner_Agents: any[];

  public menuItems: any[];
  public menuCommissions: any[];
  public menuAccounting: any[];
  public menuReporting: any[];
  public menuLogs: any[];
  public menuSettings: any[];
  public menuSms: any[];

  public menuEasypayComm: any[];

  /** gopay monitoring */
  public menuGopayMonitoring: any[];

  /** Customer menu */
  public menuCustomers: any[];

  public isCollapsed = true;

  constructor(private router: Router, private auth: AuthLayoutService,
    private loading: LoaderService) { }

  ngOnInit() {
    this.menuEasypayComm = EASYPAY_COMMISSION_ROUTES.filter(menuItem => menuItem);

    if (this.auth.getRole() === 'root' || this.auth.getRole() === 'supervisor') {
      this.isAdmin = true;

      this.menuItems = ROUTES.filter(menuItem => menuItem);
      this.menuServiceItems = ADMIN_SERVICES.filter(menuItem => menuItem);
      this.menuPartner = ADMIN_PARTNERS.filter(menuItem => menuItem);
      this.menuCommissions = COMMISSION_ROUTES.filter(menuItem => menuItem);
      this.menuReporting = RERPORTING_ROUTES.filter(menuItem => menuItem);
      this.menuLogs = LOGS_ROUTES.filter(menuItem => menuItem);
      this.menuSettings = SETTINGS_ROUTES.filter(menuItem => menuItem);

    } else if (this.auth.getRole() === 'corporate_admin') {
      this.isPartnerAdmin = true;

      this.menuPartner_Agents = CORP_AGENTS.filter(menuItem => menuItem);

      this.menuAccounting = CORP_ACCOUNTING.filter(menuItem => menuItem);
      this.menuCommissions = CORP_COMMISSION_ROUTES.filter(menuItem => menuItem);
      this.menuItems = CORPORATE_ROUTES.filter(menuItem => menuItem);
      this.menuReporting = CORP_RERPORTING_ROUTES.filter(menuItem => menuItem);
      this.menuLogs = CORP_LOGS_ROUTES.filter(menuItem => menuItem);
      this.menuSettings = CORP_SETTINGS_ROUTES.filter(menuItem => menuItem);
      this.menuSms = CORP_SMS_ROUTES.filter(menuItem => menuItem);

      /** 07-10-2021 */
      this.menuGopayMonitoring = GOPAY_MONITORING.filter(menuItem => menuItem);

      /** 28-09-2022 */
      this.menuCustomers = CUSTOMERS.filter(menuItem => menuItem);

    } else if (this.auth.getRole() === 'corporate_manager') {
      this.isPartnerManager = true;

      this.menuPartner_Agents = CORP_MAN_AGENTS.filter(menuItem => menuItem);

      this.menuItems = MANAGER_GENERAL_ROUTES.filter(menuItem => menuItem);
      this.menuReporting = CORP_RERPORTING_ROUTES.filter(menuItem => menuItem);
      this.menuAccounting = CORP_ACCOUNTING.filter(menuItem => menuItem);
      this.menuSms = MAN_SMS_ROUTES.filter(menuItem => menuItem);
    }
    this.router.events.subscribe((event) => {
      this.isCollapsed = true;
   });
  }

  signout() {
    Swal.fire({
        title: 'Sign Out',
        text: 'Do you want to sign out ?',
        type: 'question',
        confirmButtonColor: '#4ea852',
        cancelButtonColor: '#6c757d',
        confirmButtonText: 'Yes',
        showCancelButton: true,
        reverseButtons: true
    }).then((result) => {
      if (result.value) {
        this.loading.open('');
        this.auth.logout().subscribe((resp: CommonResponse) => {
          if (resp.Status === 200) {
            this.loading.closeDialog();
            this.auth.logout();
            this.router.navigate(['/login']);
          }
        });
      }
    });
  }

}
