import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse, HttpParams} from '@angular/common/http';
import { Observable, of, pipe } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';

import { environment } from 'src/environments/environment';
import { LoginResponse } from 'src/app/models/login-response';
import { LoginRequest } from 'src/app/models/login-request';
import { CommonResponse } from 'src/app/models/common-response';
import { ResetPassword } from 'src/app/pages/login/reset-password';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' } )
};

@Injectable({
  providedIn: 'root'
})
export class AuthLayoutService {

  url = environment.base_url + 'token';
  url_signout = environment.base_url + 'api/Account/Logout';

  httpOptions: {};

  redirectUrl = '';
  isLoggedIn = true;

  constructor(private Http: HttpClient) {
    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Authorization': 'bearer ' + this.getToken()
      })
    };
  }

  // Login
  login(model: LoginRequest): Observable<LoginResponse> {
    const body = new HttpParams()
    .set('grant_type', 'password')
    .set('username', model.email)
    .set('password', model.password);

    return this.Http.post<LoginResponse>(this.url, body, httpOptions).pipe(
      tap((resp: LoginResponse) => {
        this.isLoggedIn = true;
        this.saveRole(resp.userRole);
        this.saveToken(resp.access_token);
        this.saveCorporate(resp.corporate);
        this.saveExportModel(resp.export_model);
      }),
      catchError(this.handleError)
    );
  }

  logout(): Observable<CommonResponse> {

    return this.Http.post<CommonResponse>(this.url_signout, [], this.httpOptions).pipe(
      tap((resp: CommonResponse) => {
        this.saveRole('');
        this.saveToken('');
        this.saveCorporate('');
        this.saveExportModel('');
      })
    );

  }

  // Reset password
  resetPassword(model: ResetPassword): Observable<CommonResponse> {
    const _url = environment.base_url + 'api/Account/resetAdminPassword';
    // const model = new ResetPassword(email);

    return this.Http.put<CommonResponse>(_url, model, this.httpOptions).pipe(
      tap((resp: CommonResponse) => {
        console.log('');
      }),
      catchError(this.handleDefaultError)
    );
  }

  // Handle error
  private handleError(response: HttpErrorResponse) {
    if (response.error instanceof ErrorEvent) {
      console.error(`An error occured:`, response.error.message);
    } else {
      console.error(response.error.message);
    }

    const resp = new LoginResponse();
    /*console.error('message: ', response.message);
    console.error('error: ', response.error);
    console.error('status: ', response.status);
    console.error('url: ', response.url);*/

    resp.access_token = response.error.error_description;
    resp.token_type = response.error.error;
    resp.expires_in = response.status;
    return of(resp);
  }

  // TOKEN
  private saveToken(token: string) {
    sessionStorage.setItem('google-cc-token', token);
  }
  public getToken(): string {
    return sessionStorage.getItem('google-cc-token');
  }

  // ROLES
  private saveRole(role: string) {
    sessionStorage.setItem('google-cc-role', role);
  }
  public getRole(): string {
    return sessionStorage.getItem('google-cc-role');
  }

  // CORPORATE
  private saveCorporate(corporate: string) {
    sessionStorage.setItem('google-cc-corporate', corporate);
  }
  public getCorporate(): string {
    return sessionStorage.getItem('google-cc-corporate');
  }

  /** EXPORT MODEL */
  private saveExportModel(model: string) {
    sessionStorage.setItem('google-cc-export-model', model);
  }
  public getExportModel(): string {
    return sessionStorage.getItem('google-cc-export-model');
  }

  /** clear local datas */
  private clearLocalStorage(): void {
    sessionStorage.clear();
  }

  private handleDefaultError(response: any) {
    if (response.error instanceof ErrorEvent) {
      console.error(`An error occured:`, response.error.message);
    } else {
      console.error(response.error.message);
    }
    /*console.error('message: ', response.message);
    console.error('error: ', response.error);*/
    console.error('status: ', response.status);
    console.error('message ', response.error.Message);

    return of(response.error.Message);
  }
}
