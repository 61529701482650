import { Component, HostListener } from '@angular/core';

import { Router } from '@angular/router';
import { AppService } from './app.service';
import Swal from 'sweetalert2';
import { AuthLayoutService } from './layouts/auth-layout/auth-layout.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'Kolector';
  isLoggedIn = false;
  time: any;

  @HostListener('window:mouseup', ['$event'])
  onMouseUp(event) {
    console.log(event);
    this.resetTimer();
  }

  constructor(private router: Router) {}

  resetTimer() {
    clearTimeout(this.time);
    this.time = setTimeout(this.logout, 900000); /** after 15 min */
  }

  logout() {
    if (sessionStorage.getItem('google-cc-token') !== '') {
      // this.router.navigate(['/login']);
      sessionStorage.clear();
      window.location.replace('http://kolector.easypay.cm/');
      //window.location.replace('http://102.132.17.228/');
      //window.location.replace('http://localhost:4200/');
    } else {
      console.log('timeoute: false');
    }
  }
}
