import { Component, OnInit, ElementRef } from '@angular/core';
import { ROUTES } from '../sidebar/sidebar.component';
import { Location, LocationStrategy, PathLocationStrategy } from '@angular/common';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';
import { AuthLayoutService } from 'src/app/layouts/auth-layout/auth-layout.service';
import { LoaderService } from 'src/app/dialogs/loader/loader.service';
import { CommonResponse } from 'src/app/models/common-response';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {
  public focus;
  public listTitles: any[];
  public location: Location;
  constructor(location: Location,  private element: ElementRef, private router: Router,
    private auth_service: AuthLayoutService, private loading: LoaderService) {
    this.location = location;
  }

  ngOnInit() {
    this.listTitles = ROUTES.filter(listTitle => listTitle);
  }
  getTitle() {
    let titlee = this.location.prepareExternalUrl(this.location.path());
    if (titlee.charAt(0) === '#') {
        titlee = titlee.slice( 2 );
    }
    for (let item = 0; item < this.listTitles.length; item++) {
        if (this.listTitles[item].path === titlee) {
            return this.listTitles[item].title;
        }
    }
    return this.auth_service.getCorporate();
  }

  getCorporate(): string {
    return this.auth_service.getCorporate();
  }

  signout() {
    Swal.fire({
        title: 'Sign Out',
        text: 'Do you want to sign out ?',
        type: 'question',
        confirmButtonColor: '#4ea852',
        cancelButtonColor: '#6c757d',
        confirmButtonText: 'Yes',
        showCancelButton: true,
        reverseButtons: true
    }).then((result) => {
        if (result.value) {
          this.loading.open('');
          this.auth_service.logout().subscribe((resp: CommonResponse) => {
            if (resp.Status === 200) {
              this.loading.closeDialog();
              this.auth_service.logout();
              this.router.navigate(['/login']);
            }
          });
        }
    });
  }
}
