import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material';
import { LoaderComponent } from './loader.component';

@Injectable({
  providedIn: 'root'
})
export class LoaderService {

  dialogRef: any;
  constructor(private dialog: MatDialog) { }

  open(title: string): void {
    this.dialogRef = this.dialog.open(LoaderComponent, {
      width: '350px',
      height: '200px',
      disableClose: true,
      data: { title: title }
    });

    this.dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
      // this.animal = result;
    });
  }

  closeDialog(): void {
    this.dialogRef.close();
  }
}
